import { nextTick, watch, ref, onUnmounted, onMounted, computed } from 'vue'
import { debounce } from '@shein/common-function'
import { checkTowDomWrap } from 'public/src/pages/product_app/store/modules/product_detail/utils/price.js'

// 换行元素前面插入一个元素
const handleLineBreaksInContainer = (productPriceRef) => {

  if (!productPriceRef.value?.children) return

  // 移除之前插入的占位节点
  for (let item of productPriceRef.value.children) {
    if (item.dataset.isInserDom) {
      productPriceRef.value.removeChild(item)
    }
  }

  // 获取每个元素的顶部和底部的页面位置
  // 头部位置低于前一个元素的底部位置那说明当前元素发生换行
  let currentDom = {}
  let preDom = {}
  let needInsertDom = []
  for (let item of productPriceRef.value.children) {
    let top = item.getBoundingClientRect().top
    let height = item.offsetHeight
    let bottom = top + height
    currentDom = {
      top,
      bottom
    }
    if (preDom.top && currentDom.top >= preDom.bottom && !item.dataset.noInsert && height) {
      needInsertDom.push(item)
    }
    preDom = currentDom
  }

  console.log('needInsertDom', needInsertDom)
  if (needInsertDom.length === 0) return
  // 在换行的元素前插入一个width 100% height .1333rem 的 div
  for (let item of needInsertDom) {
    let div = document.createElement('div')
    div.style.width = '100%'
    div.style.height = '.1067rem'
    div.dataset.isInserDom = 1
    if (item.dataset.wrapHeight) div.style.height = item.dataset.wrapHeight
    productPriceRef.value.insertBefore(div, item)
  }
}

const useCalcDomFn = ({
  realTimeReady,
  isFloorType
}) => {

  const domIds = computed(() => {
    return {
      container: isFloorType.value ? 'productPriceFloorId' : 'productPriceId',
      mainPrice: isFloorType.value ? 'productMainPriceFloorId' : 'productMainPriceId',
      estimatedTag: isFloorType.value ? 'productEstimatedTagFloorId' : 'productEstimatedTagId',
      discountInfo: isFloorType.value ? 'productDiscountInfoFloorId' : 'productDiscountId'
    }
  })
  
  const containerDom = computed(() =>{
    return document.getElementById(domIds.value.container)
  })

  const mainPriceDom = computed(() =>{
    return document.getElementById(domIds.value.mainPrice)
  })
  const estimatedTagDom = computed(() =>{
    return document.getElementById(domIds.value.estimatedTag)
  })
  const discountInfoDom = computed(() =>{
    return document.getElementById(domIds.value.discountInfo)
  })

  /* 换行改变到手价和折扣气泡小三角位置计算 */
  const discountInfoIsWrap = ref(-1)
  const estiamtedTagIsWrap = ref(-1)
  const calcuDiscountInfoIsWrap = (mainPriceDom, discountInfoDom) => {
    if (!discountInfoDom.value?.$el) return
    discountInfoIsWrap.value = Number(checkTowDomWrap(mainPriceDom.value, discountInfoDom.value.$el))
  }
  const calcuEstiamtedTagIsWrap = (mainPriceDom, estimatedTagDom) => {
    if (!estimatedTagDom.value?.$el) return
    estiamtedTagIsWrap.value = Number(checkTowDomWrap(mainPriceDom.value, estimatedTagDom.value.$el))
  }
  /* 换行改变到手价和折扣气泡小三角位置计算 */

  const calcDomFn = debounce({
    func: () => {
      handleLineBreaksInContainer(containerDom)
      calcuDiscountInfoIsWrap(mainPriceDom, discountInfoDom)
      calcuEstiamtedTagIsWrap(mainPriceDom, estimatedTagDom)
      domUpdateObserver?.disconnect?.()
      domUpdateObserver = null
    },
    wait: 1000
  })

  let domUpdateObserver = new MutationObserver(() => {
    calcDomFn()
  })

  watch(() => realTimeReady.value, async (val) => {
    if (!val) return
    await nextTick()
    if (!containerDom.value) return
    if (isFloorType.value) return
    if (domUpdateObserver?.observe) {
      domUpdateObserver.observe(containerDom.value, {
        childList: true,
      })
    } else {
      domUpdateObserver = new MutationObserver(() => {
        calcDomFn()
      })
      domUpdateObserver.observe(containerDom.value, {
        childList: true,
      })
    }
  }, { immediate: true })

  onMounted(() => {
    if (isFloorType.value) {
      appEventCenter.on('productDetailFloorCalcPriceDomFn', () => {
        calcDomFn()
      })
    } else {
      appEventCenter.on('productDetailcalcPriceDomFn', () => {
        if (domUpdateObserver) return
        calcDomFn()
      })
    }
  })

  onUnmounted(() => {
    domUpdateObserver?.disconnect?.()
    appEventCenter?.off('productDetailcalcPriceDomFn')
    appEventCenter?.off('productDetailFloorCalcPriceDomFn')
  })

  return {
    discountInfoIsWrap,
    estiamtedTagIsWrap,
    domIds
  }
}


export default useCalcDomFn

